#iframe{
    width: 100%;
    height: calc(100vh - var(--finance-header-height));
    margin-top: calc(var(--finance-header-height) + 2px);
    border: 0; 
}

.mainHeader {
    border-bottom: 1px solid grey;
    box-shadow: 0 1px 5px 0 #aaa;
}

body {
    margin: 0;
    overflow-y: hidden;
}