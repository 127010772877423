#iframe {
  height: calc(100vh - var(--finance-header-height));
  margin-top: calc(var(--finance-header-height)  + 2px);
  border: 0;
  width: 100%;
}

.mainHeader {
  border-bottom: 1px solid gray;
  box-shadow: 0 1px 5px #aaa;
}

body {
  margin: 0;
  overflow-y: hidden;
}
/*# sourceMappingURL=index.a96720de.css.map */
